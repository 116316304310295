:root {
  --color-primary: #e14469;
  --color-secondary: #741e32;
  --dark-gray: #817e7c;
}

.pageContainer {
  background-color: var(--color-primary);
  min-height: 100vh;
}

.contentContainer {
  width: 400px;
  margin: 90px auto;
}

.optionSlectContainer {
  background-color: #fff;
  border-radius: 25px;
  padding: 2rem;
}

.optionSelect {
  margin-top: 10px;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background-color: white;
  border-radius: 50px;
  width: 100%;
  padding: 0.9rem 0.5rem;
  cursor: pointer;
}
.title {
  margin-bottom: 1rem;
}
.optionSelect:hover {
  background-color: var(--color-primary);
  color: white;
}

.optionSelect:disabled {
  background-color: #e5e5e5;
  color: black;
  border: none;
  cursor: not-allowed;
}

.FormikContainer {
  max-width: 550px;
  margin: 120px auto;
  background-color: white;
  padding: 2rem;
  border-radius: 25px;
}
.helpText {
  color: var(--dark-gray);
}
.Label {
  display: block !important;
  margin-bottom: 20px !important;
  line-height: 2rem;
}
.ReviewAnimation {
  margin: 0 auto;
  width: 100%;
}
.radioButtonContainer {
  display: flex;
  flex-wrap: wrap;
}

.radioButtonItem {
  margin-right: 10px;
}
.radioButton {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 5px;
}
.radioButton:checked {
  background-color: var(--color-primary);
}

.radioButtonFlexBox {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .radioButtonContainer {
    gap: 0.6rem;
  }
  .radioButtonItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
  }
}
.filledButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
  width: 100%;
  border-radius: 25px;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.filledButton:hover {
  transform: scale(1.03);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.outlinedButton {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background-color: white;
  border-radius: 25px;
  width: 100%;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.outlinedButton:hover {
  background-color: var(--color-primary);
  color: white;
}
.outlinedButton:disabled {
  background-color: #e5e5e5;
  color: black;
  border: none;
  cursor: not-allowed;
}

.questionContainer {
  margin-bottom: 4rem;
}
.MultiChoiceContainer {
  display: flex !important;
  flex-direction: column;
  margin-bottom: 1rem;
}
.multuiChoiceRadioButtonItem {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  margin-bottom: 0.6rem;
}
@media screen and (max-width: 600px) {
  /* reducing the radio botton size on mobile */
  .radioButton {
    width: 1em;
    height: 1em;
  }
  .FormikContainer {
    max-width: 90%;
  }
}
