.auth {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.auth__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.auth__form-content {
    max-width: 500px;
    width: 100%;
}

.auth__image {

    img {
        object-fit: cover;
        object-position: right;
    }
}
