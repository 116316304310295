:root {
  --color-primary: #e14469;
  --color-secondary: #741e32;
}

.HeadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  height: 200px;
  background-color: #e14469;
  color: #fff;
  clip-path: ellipse(120% 100% at 52.1% 0%);
}

.PremisesType {
  color: #741e32;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.ContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-top: 1rem;
  height: 100% !important;
}

.Card {
  border: 1px solid #b7b6b6;
  border-radius: 25px;
  padding: 2rem;
  text-align: center;
  max-width: 350px;
}
.Card:hover {
  border-color: #e14469;
}

.SubmitButton {
  background-color: #e14469;
  color: #fff;
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 50px;
  min-width: 100px;
  cursor: pointer;
}
.SubmitButton:hover {
  color: #fff;
  font-weight: 600;
}
.notFound {
  margin-top: 2rem;
  text-align: center;
  color: darkslategrey;
}
