.NotificationItemContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 0;
  margin-bottom: 10px;
}
.NotificationItemContainer:hover {
  background-color: #f2f2f2;
  border-radius: 5px;
  cursor: pointer;
}

.unreadIndicator {
  display: block;
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
  background-color: #1aaae8;
}

.notificationText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.2rem;
}
.timeStamp {
  font-size: 0.7rem;
  color: #1aaae8;
}
