/* NEWPOST */

.NewPostContainer {
  width: 660px;
  background-color: white;
  border-radius: 0.85rem;
  border: 1px solid #e8e8e8;
  margin: 0 auto 1.5rem auto;
  display: block;
  position: relative;
  z-index: 11 !important;
  position: relative !important;
}
.NewPostContent {
  padding: 1.5rem;
}
.NewPostContent textarea {
  border: 0 solid transparent !important;
  border-color: transparent;
  padding: 0;
  resize: none;
  outline: 0 !important;
  box-shadow: none !important;
}
.NewPostInput {
  display: flex !important;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  flex: 1;
  transition: all 0.3s;
  border-radius: 0.65rem;
  color: #363636;
  background-color: #fff;
  line-height: 1.5;
  border: 0 solid transparent !important;
  border-color: transparent;
  padding: 0;
  resize: none;
  outline: 0 !important;
  box-shadow: none !important;
}
.NewPostInput:focus {
  outline: none !important;
}

.NewPostHiddenOptions {
  display: flex;
  padding: 8px;
  border-top: 1px solid #e8e8e8;
  height: auto;
  background: #fafafa;
  border-radius: 0 0 6px 6px;
}

.PublishButton {
  width: 100%;
  background: #5596e6;
  border-color: #5596e6;
  color: #fff;
}
.PublishButton:disabled {
  pointer-events: none;
  opacity: 0.4;
  cursor: default !important;
}

.FileInputContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 16px;
  margin-right: 10px;
  background: #f7f7f7;
  border-radius: 500px;
  font-size: 0.85rem;
  color: #888da8;
  transition: all 0.3s;
}

.FileInputContainer svg {
  height: 20px;
  width: 20px;
  transition: all 0.3s;
}
.FileInputContainer input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.FileInputContainer span {
  display: block;
  padding: 0 8px;
}
.FileInputContainer input[type='file' i] {
  appearance: none;
  background-color: initial;
  cursor: default;
  align-items: baseline;
  color: inherit;
  text-overflow: ellipsis;
  white-space: pre;
  text-align: start !important;
  padding: initial;
  border: initial;
  overflow: hidden !important;
}

/* overlay */

.FeedOverlay {
  opacity: 1 !important;
  z-index: 10 !important;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* Feed */
.FeedItemCard {
  position: relative;
  margin-bottom: 1.5rem;
  border: 1px solid #e8e8e8;
  background: #fff;
  border-radius: 0.85rem;
  box-shadow: none;
  width: 660px;
  margin: 0 auto 1.5rem auto;
}

.FeedItemHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 16px 0 16px;
}
.FeedItemUser {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.FeedItemUserImage {
  display: block;
  position: relative;
}
.FeedItemUserInfo {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}
.FeedItemUserInfo a {
  font-size: 0.9rem;
  font-weight: 500;
  color: #393a4f;
  transition: color 0.3s;
  line-height: 1.1;
}

.FeedItemUserInfo span {
  font-size: 0.8rem;
  color: #999;
}

.FeedItemDropdown {
  margin-left: auto;
  display: inline-flex;
  position: relative;
  vertical-align: top;
}
.FeedBody {
  padding: 16px 16px 0 16px;
  word-wrap: break-word;
}

.FeedPostText {
  color: #757a91;
  font-size: 0.9rem;
}
.FeedPostLink {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  background: #f5f5f5;
  margin: 16px 0 10px 0;
}
.FeedPostLinkImage {
  position: relative;
}
.FeedPostLinkImage img {
  display: block;
  height: 130px;
  width: 180px;
  min-width: 180px;
  border-radius: 6px;
  -o-object-fit: cover;
  object-fit: cover;
}

.FeedPostLinkContent {
  margin-left: 12px;
}
.FeedPostLinkContent p {
  font-size: 0.9rem;
  color: #4a4a4a;
}
.FeedPostFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  padding: 16px 0;
  margin: 0;
  flex-wrap: 'wrap';
}
@media screen and (max-width: 410px) {
  .FeedPostFooter {
    flex-direction: column;
    align-items: baseline;
    gap: 7 !important;
  }
  .FeedPostLikedUsers {
    margin-bottom: 20px;
  }
}
.FeedPostFooterActions {
  /* min-width: 400px; */
  margin-left: auto;
  display: flex;
  align-items: stretch;
}

.FeedLoadMore {
  margin: 0 auto;
  text-align: center;
}
.FeedLoadMoreButton {
  box-sizing: border-box;
  display: inline-block;
  width: 9em;
  height: 2.75em;
  padding: 0.5em 1em;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  text-align: center;
  color: #757a91 !important;
  white-space: nowrap;
  line-height: 1.5;
  font-size: 1em;
  text-decoration: none;
  margin-bottom: 4rem !important;
}
/* mobile */

@media screen and (max-width: 768px) {
  .NewPostContainer {
    width: 100%;
  }

  .FeedItemCard {
    width: 100%;
  }
  .FeedPostLinkContent {
    margin-left: 0 !important;
    padding: 16px 6px;
  }
  .FeedPostLinkContent p {
    font-size: 0.7rem;
    word-wrap: break-word;
  }
  .FeedPostLink {
    flex-direction: column;
  }
  .FeedPostLinkImage img {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-height: 180px;
  }
}

/* Comments */

.CommentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.CommentHeader h4 {
  font-size: 1rem;
  font-weight: 500;
  color: #757a91;
  margin: 0 !important;
}
.CommentHeaderCloseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  transition: background 0.3s;
  margin: 0 !important;
}
.CommentHeaderCloseButton svg {
  height: 18px;
  width: 18px;
  stroke: #757a91;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.CommentBody {
  padding: 16px;
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden !important;
}
.CommentInputForm {
  width: 100%;
}
.CommentInputControl {
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: inherit;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}
.CommentInput {
  border-color: #e0e0e0;
  border-bottom: none;
  border-radius: 0.65rem 0.65rem 0 0;
  resize: none;
  box-shadow: none !important;
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: calc(0.75em - 1px);
  width: 100%;
  background-color: #fff;
  -webkit-appearance: none;
  align-items: center;
}
.CommentInput:focus {
  outline: 0 !important;
}
.CommentActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 0.65rem 0.65rem;
}
.CommentButton {
  background: #3d70b2;
  border-color: #3d70b2;
  color: #fff;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  padding: 12px 22px !important;
  border-radius: 0.65rem !important;
  transition: all 0.3s !important;
}
.CommentEmojiButton {
  background: transparent !important;
  border-color: transparent !important;
  border: none !important;
  margin-right: 26px !important;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.CommentBodyText p {
  font-size: 0.9rem !important;
  color: #757a91;
  margin: 1.2rem 0 1.2rem 3.6rem;
  line-height: 1.2rem;
}
.CommentFooter {
  margin: 8px 16px 16px 16px;
  background-color: transparent;
  border-top: 1px solid #ededed;
  align-items: stretch;
  display: flex;
}
.CommentFooterForm {
  width: 100%;
  overflow-x: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: inherit;
}

/* likedUsers */

.FeedPostLikedUsers {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 600px !important;
  /* min-width: 300px !important; */
}
.FeedPostLikedUsersImages img {
  width: 2em !important;
  height: 2em !important;
  border-radius: 500rem !important;
  object-fit: cover !important;
  border: 2px solid white;
}

.FeedPostLikedUsersImages:not(:first-child) img {
  margin-left: -12px;
}

.likedUsersTextRendered {
  margin-left: 10px;
  line-height: 1.4;
}
.likedUsersTextRendered p {
  font-size: 0.8rem;
  color: #888da8;
  display: block;
  margin: 0 !important;
  padding: 0 !important;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
}
.likedUsersTextRendered p a {
  color: #393a4f;
  font-weight: 500;
}

/* commentButton.js file */

.CommentCount {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 3px;
  cursor: pointer;
}
.CommentCount svg {
  height: 18px;
  width: 18px;
  stroke: #888da8;
}
.CommentCount span {
  display: block;
  font-size: 0.9rem;
  color: #888da8;
  margin: 0 5px;
}

/* newpost preview images */
.NewPostPreviewImages {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.NewPostPreviewImages img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 10%;
  opacity: 0.7;
}

.NewPostPreviewImages img:hover {
  opacity: 1;
}

.NewPostPreviewImages img:not(:first-child) {
  margin-left: 10px;
}

.NewPostPreviewImages span {
  font-size: 1.2rem;
  font-weight: 100;
  margin-left: 10px;
  cursor: pointer;
}

/* Feed images */

.FeedPostImage {
  margin: 10px 0;
}
.FeedPostImage img {
  display: block;
  border-radius: 0.75rem;
  height: auto;
  max-width: 100%;
  height: 300px;
  object-fit: contain;
}
.FeedPostMultipleImage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  gap: 10px;
}
.FeedPostImage > .FeedPostMultipleImage img {
  display: block;
  border-radius: 0.75rem;
  height: auto;
  width: 48%;
  height: auto;
  object-fit: contain;
}
