.dropzone {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  cursor: pointer;
}

.dropzone__input {
  width: 100%;
  border: 2px dashed #ddd;
  padding: 20px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 20px;
  &:focus {
    outline: none;
  }
}

.dropzone__file {
  background: #f7f7f7;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px 0;
  border-radius: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
