.feed__new_post_container {
  -webkit-border-radius: 9px !important;
  -moz-border-radius: 9px !important;
  border-radius: 35px !important;
  max-width: 600px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.feed__new_post__input_container {
  display: flex !important;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
}
.feed__new_post__input_round {
  flex: 1;
  border-radius: 25px !important;
}
.feed__new_post__input_round textarea {
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  border-radius: 25px !important;
  height: 50px;
}
.feed__post__shared_link {
  width: 100% !important;
  display: flex !important;
}
.feed__post__shared_link img {
  height: 100%;
  object-fit: cover !important;
  max-width: 170px !important;
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  max-height: 180px;
}
.feed__post__text_extra {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.feed__post__shared_link_container {
  border-left: 2px solid saddlebrown;
  min-width: 380px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  border-radius: 15px !important;
  width: 270px !important;
  background-color: #fff !important;
}
.feed__post__shared_link p {
  padding-left: 1em;
  padding-bottom: 1em;
  padding-top: 1em;
  text-decoration: none;
  color: black;
  font-size: 0.9em !important;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.feed__post__shared_link p span {
  color: gray;
}
@media screen and (max-width: 680px) {
  .feed__post__shared_link {
    flex-direction: column !important;
  }
  .feed__post__shared_link_container {
    border-left: 2px solid saddlebrown;
    min-width: 200px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
    border-radius: 15px !important;
    width: 270px !important;
    background-color: #fff !important;
  }
  .feed__post__shared_link img {
    max-height: 80px !important;
    height: 80px;
    object-fit: cover !important;
    width: 100%;
    max-width: 100% !important;
    border-top-left-radius: 15px !important;
    border-bottom-left-radius: 0 !important;
    margin-left: -2px;
  }
}
.feed__avatar_picture img,
.feed__avatar_picture {
  // width: 2.5em !important;
  // height: 2.5em !important;
  border-radius: 500rem !important;
  object-fit: cover !important;
}

.feed__post__more {
  border: none;
  font-size: 1.1em;
  float: right;
  letter-spacing: 1.4px;
}
.feed__post__more .text {
  transform: rotate(90deg);
}
.feed__post_deleted {
  margin-left: 3em !important;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
  color: gray;
  text-align: center;
}

.feed__like_button {
  border: none;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  gap: 1px;
  font-size: 11px;
  align-items: center;
  .active {
    color: rgb(212, 31, 31) !important;
  }
}
.feed__like_button:hover {
  color: rgb(212, 31, 31);
}
.feed__like_button:disabled {
  color: rgb(224, 110, 110);
  cursor: pointer;
}

// Comments

.comment_delete_item {
  font-size: 0.5em !important;
}
.read_more_button {
  border: none;
  margin-left: 5px;
  border-radius: 5px;
  background-color: none;
  color: rgba(48, 48, 48, 0.7);
}
.read_more_button:hover {
  text-decoration: underline;
}
