:root {
  font-size: 16px;
  font-family: "Open Sans";
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --transition-speed: 600ms;
}

.header__navbar {
  min-height: 65px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: #000;
  transition: all 0.3s ease;
}

.header__navbar--transparent {
  background: none;

  .header__menu {
    ul {
      a {
        color: #fff;
      }
    }
  }
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100vw !important;
}

.header__logo {
  margin-right: 30px;
  img {
    width: 90px;
    height: auto;
  }
}
.header__icon {
  display: none;
}

.header__menu {
  .header__horizontal__menu {
    margin: 0;
    padding: 0;
    align-self: flex-end;
    display: flex;

    li {
      display: flex;
      margin-left: 3em;
      align-items: center;
      height: 2rem;
      color: var(--text-primary);
      text-decoration: none;
      transition: var(--transition-speed);
      text-align: center;
    }
    li:hover {
      background: var(--bg-secondary);
      color: var(--text-secondary);
    }

    a {
      color: #fff;
      text-transform: uppercase;
      // opacity: 0.8;
      font-weight: bold;
      white-space: nowrap;

      &:hover {
        opacity: 1;
      }

      &.active {
        opacity: 1;
      }
    }
  }
}

.header__home__circle__icon {
  .number_label {
    top: 1em !important;
    left: auto !important;
    border-radius: 50%;

  }
}
.header__home__circle__icon:hover {
  color: #ee3f61;
}

.header__home__circle__icon:hover,
.header__home__icon_notification:hover {
  color: #972038;
  height: 100%;
}
.header__home__icon {
  padding: 0 !important;
}
.header__home__icon_notification {
  font-size: 1.5em;
}
.header__home__accordion {
  display: none !important;
}

.header_home_accordion_active {
  display: flex;
  flex-direction: column !important;
  height: auto;
  top: 50px;
  // margin-top: 65px;
  width: 100%;
  background-color: rgb(58, 53, 53);
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 !important;
  // transition: all 18.9s ease;
  animation: rotateMenu 400ms ease-in-out forwards;
  transform-origin: top center;
  .header__home__circle__icon {
    text-align: center;
    display: flex;
    align-items: center;
    gap: 20px;
    align-content: center;
  }
  .header__home__accordion_item {
    height: 3em;
    margin: 1em auto;
    color: #fff !important;
    width: 100px;
    text-align: center;
    font-size: 1rem !important;
    color: var(--text-primary);
    text-decoration: none;
    filter: grayscale(100%) opacity(0.9);
    // transition: var(--transition-speed);
  }
  .header__home__accordion_item:hover {
    filter: grayscale(0%) opacity(1);
  }
}
@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@media screen and (max-width: 600px) {
  .header__icon {
    display: block;
    margin-right: 0px;
    img {
      width: 35px;
      height: auto;
    }
  }
  .header__logo {
    display: none;
  }
  .header__navbar {
    width: 100%;
    bottom: 0;
    top: auto;
    height: 5rem;
  }

  .header__inner {
    height: 65px;
    display: flex;
    width: 100vw;
    justify-content: space-evenly;
    top: auto;
    flex-direction: row;
  }

  .header__home__circle__icon {
    margin: 0 auto;
  }

  .header__menu {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: row !important;
    .header__horizontal__menu {
      display: flex;
      flex-direction: row !important;
      width: 100%;
      align-self: flex-end;
      text-align: center;
    }

    .header__horizontal__menu li {
      justify-content: center;
      text-align: center;
    }
  }
  .header_home_accordion_active {
    bottom: 55px !important;
    top: auto;
    transition: all 5.9s ease-in-out;

    .header__home__accordion_item {
      height: 3em;
      margin-top: 1em;
      color: #fff !important;
      width: 75px;
      justify-content: space-between;
      font-size: 1rem !important;
      color: var(--text-primary);
      text-decoration: none;
      filter: grayscale(100%) opacity(0.9);
      display: flex;
      justify-content: center;
      width: 100%;
      // transition: var(--transition-speed);
    }

  }
}


.header__feed__avatar_picture img,
.header__feed__avatar_picture{
    width: 2.2em !important;
    height: 2.2em !important;
    border-radius: 500rem !important;
    object-fit: cover !important;
    opacity: 1 !important;
    border: 2px solid #fff !important;

}