body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #6649b8;
}
.wrapper {
  padding: 20px 0;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 65px;
  overflow: hidden;
}

.slick-prev:before,
.slick-next:before {
  color: #d23a65 !important;
}
.tooltip {
  top: 190px !important;
  left: 20px !important;
  position: absolute;
  display: block;
  padding: 5px;
  color: white;
  background-color: #d23a65;
}

@media screen and (max-width: 600px) {
  .main__content {
    margin-top: 56px;
    margin-bottom: 85px !important;
  }
}
.main--home {
  // background-image: url('./../images/background.jpg');
  background-image: url('./../images/background.jpg');
  background-size: cover;
  min-height: 100vh;
}

.required-document {
  background-color: #b43e48 !important;
}
