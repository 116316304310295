.message_unread::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  background-image: radial-gradient(circle at center, red 5px, transparent 5px),
    radial-gradient(circle at center, red 5px, transparent 5px);
  background-size: 20px 20px;
  left: -1px;
}

.message_bubble {
  padding: 35px;
  background-color: rgb(226, 249, 246);
  border-radius: 45px;
}

.message_thread_top_bar {
  background-color: #e0e1e2;
  width: 100%;
  padding: 3px 20px;
  margin: -5px 0px 26px 0px;
  border-radius: 30px;
}
