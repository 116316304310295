.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 20px 0;
}

.home__content {
  max-width: 600px;
}

.home__title {
  font-size: 60px;
  color: #fff;
  letter-spacing: 20px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
}

.home__text {
  margin: 50px 0;
  color: #fff;
  font-size: 16px;
}

.home__button_container {
  display: flex;
}
.home__button {
  background: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.home__button_login {
  background: #9129ab;
  color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  margin-left: 1em;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
