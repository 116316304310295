.suggestChangeTrigger {
  cursor: pointer;
  text-decoration: underline;
  color: #0070f3;
}
.modalCloseButton {
  cursor: pointer;
  border: none;
  background-color: transparent;
  float: right;
}
.ReviewMetaData {
  display: flex;
  gap: 20px;
  font-size: 0.8rem;
  color: #585757;
}
.reviewModalContainer {
  border: none !important;
  padding: 0 !important;
}

.NoReviewsYet {
  font-size: 1rem;
  color: #585757;
  text-align: center;
  padding: 20px;
  line-height: 1.6rem;
}

.agencyReviewModalContent__review {
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: #f3f4f5;
  border-radius: 20px;
  padding: 30px 20px;
  margin-bottom: 1rem;
}
