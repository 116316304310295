.explore__search div {
  width: 100% !important;
}

.explore__tabs .menu{
  display: flex;
  flex-wrap: wrap;
}
.explore__tabs .menu .item{
  color: rgb(226, 226, 226) !important;
}
.explore__tabs .menu .active{
  color: rgb(255, 255, 255) !important;
}
@media (max-width: 640px) {
  .explore__tabs .menu .item {
    padding: 10px !important;
  }
}