body {
  background: #fff;
  overflow-y: scroll;
}

ul {
  list-style-position: inside;
  list-style-type: none;
}

.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ui.dimmer {
  position: fixed;
}

// Button

.ui.mini.button {
  font-size: 10px;
}

// Gutters

.gutters10 {
  &.ui.grid {
    margin-left: -5px;
    margin-right: -5px;
  }

  &.ui.grid > .column:not(.row),
  &.ui.grid > .row > .column {
    padding-left: 5px;
    padding-right: 5px;
  }
}

// Dropdown

.ui.selection.dropdown {
  min-width: auto;
}

// Card

.ui.card .meta,
.ui.cards > .card .meta {
  span {
    margin: 0;
  }
}

// React Toast Notifications

.react-toast-notifications__container {
  z-index: 1001 !important;
}
@media screen and (max-width: 900px) {
  .react-toast-notifications__container {
    width: 80% !important;
  }
}

// Margin

.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.pt-20 {
  padding-top: 20px !important;
}

.w-100 {
  width: 100% !important;
}
.SyrkleButton {
  background-color: #e43a5d !important;
  color: #fff;
}

.float-right {
  float: right !important;
}

.d-flex {
  display: flex;
}

.d-flex-space-a {
  display: flex;
  justify-content: space-around;
}
.d-flex-space-b {
  display: flex;
  justify-content: space-between;
}
