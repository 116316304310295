.profile__user_cover {
  max-height: 300px;
  min-height: 300px;
  object-fit: cover;
}
.profile__user_image {
  border: 1px solid #ed3e61;
  margin: -80px auto 0 auto;
  background-color: white !important;
  width: 150px;
  height: 150px;
  max-height: 150px;
  min-height: 150px;
  object-fit: cover;
}

.profile__account_status_container {
  display: flex;
  margin: 1rem;
  align-items: center;
  justify-content: space-between;
}
.profile__edit_image_container {
  text-align: center;
}
.profile__edit_image_container img {
  margin: 0 auto;
}
